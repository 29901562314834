<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- Personal Details tab -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <!-- <font-awesome-icon icon="fa-regular fa-user" /> -->
          <span class="font-weight-bold">Personal Details </span>
        </template>

        <account-setting-general
          v-if="options.general"
          :general-data="options.general"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- VAT / GST -->
      <!-- <b-tab>
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">VAT / GST</span>
      </template>
      <account-vat-gst-settings />
    </b-tab> -->

      <!-- Company Details -->
      <!-- <b-tab>
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Payout Details</span>
      </template>

      <account-setting-company-details
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab> -->

      <!-- Address Tab -->
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Address</span>
        </template>
        <address-list v-if="options.address" />
      </b-tab>

      <!-- Payment Tab -->
      <b-tab :active="isPayout ? true : false">
        <template #title>
          <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Payout Details</span>
        </template>
        <account-payment-information id="payout" />
      </b-tab>

      <!-- Security Tab -->

      <b-tab>
        <template #title>
          <feather-icon icon="ShieldIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Security &amp; Privacy</span>
        </template>
        <account-security-settings />
      </b-tab>
    </b-tabs>
    <b-row>
      <b-col class="text-center">
        <a href="/">Back to Home</a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
// import AccountVatGstSettings from "./AccountVATGSTSettings.vue";
// import AccountSettingCompanyDetails from "./AccountSettingCompanyDetails.vue";
import AccountAddressSettings from "./AccountAddressSettings.vue";
import AccountPaymentInformation from "./AccountPaymentInformation.vue";
import AccountSecuritySettings from "./AccountSecuritySettings.vue";
import AddressList from "../Address/AddressList.vue";
import ProfileServices from "@/apiServices/ProfileServices";
import storageService from "@/apiServices/storageService";

export default {
  metaInfo: {
    title: "Account Setting | Revivify",
    htmlAttrs: {
      lang: "en-US",
    },
  },
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    AccountSettingGeneral,
    // AccountSettingCompanyDetails,
    AccountAddressSettings,
    AccountPaymentInformation,
    // AccountVatGstSettings,
    AccountSecuritySettings,
    AddressList,
  },
  data() {
    return {
      options: {},
      profileData: null,

      isPayout: false,
    };
  },

  beforeMount() {
    let url = new URL(window.location.href);
    var payout = url.searchParams.get("payout");

    if (payout == "true") {
      this.isPayout = true;
      console.log("payout: ", payout);
    }
  },

  beforeCreate() {
    const profileData = storageService.UserData.getUserProfile();
    let uid = JSON.parse(profileData);
    const sendData = { id: uid.user_id };

    ProfileServices.getUserProfileData(sendData)
      .then((response) => {
        console.log("GET USER DATA: ", response);
        this.profileData = response.data.data;
        this.options = response.data.data;
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
};
</script>
